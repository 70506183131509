import { useSelector } from 'react-redux';
import { selectUserCountryCode } from '../../../../cross-cutting-concerns/authentication/state/authenticationSelectors';
import { Country } from '../../../../cross-cutting-concerns/translations/interfaces/Translations.types';
import { Translations } from '../../../../cross-cutting-concerns/translations/Translations';
import { CURRENT_LICENSE_AGREEMENT_VERSION } from '../../constants';
import { licenseAgreementContentGermany } from './country/germany/licenseAgreementContentGermany';
import { licenseAgreementContentAustralia } from './country/australia/licenseAgreementContentAustralia';
import { licenseAgreementContentAustria } from './country/austria/licenseAgreementContentAustria';
import { licenseAgreementContentBelgium } from './country/belgium/licenseAgreementContentBelgium';
import { licenseAgreementContentCzechRepublic } from './country/czech-republic/licenseAgreementContentCzechRepublic';
import { licenseAgreementContentDenmark } from './country/denmark/licenseAgreementContentDenmark';
import { licenseAgreementContentFrance } from './country/france/licenseAgreementContentFrance';
import { licenseAgreementContentItaly } from './country/italy/licenseAgreementContentItaly';
import { licenseAgreementContentNetherlands } from './country/netherlands/licenseAgreementContentNetherlands';
import { licenseAgreementContentNorway } from './country/norway/licenseAgreementContentNorway';
import { licenseAgreementContentSweden } from './country/sweden/licenseAgreementContentSweden';
import { licenseAgreementContentSwitzerland } from './country/switzerland/licenseAgreementContentSwitzerland';
import { licenseAgreementContentUnitedKingdom } from './country/united-kingdom/licenseAgreementContentUnitedKingdom';
import { licenseAgreementContentUsa } from './country/usa/licenseAgreementContentUsa';
import { StyledLicenseAgreementContent } from './LicenseAgreementContent.styles';

export const LicenseAgreementContent = (): JSX.Element => {
  const userCountryCode = useSelector(selectUserCountryCode);
  const country = Translations.getCountryByCountryCode(userCountryCode);

  // Use script at tools/contract-word-extractor to generate baseline markup from original .docx file
  // This will need so manual adaptions for correctly formatting headings, subheadings and adding spacers
  // (potentially more depending on the source file)
  const ContentByCountryMap: Record<Country, any> = {
    [Country.AUSTRALIA]: licenseAgreementContentAustralia,
    [Country.AUSTRIA]: licenseAgreementContentAustria,
    [Country.BELGIUM]: licenseAgreementContentBelgium,
    [Country.CZECH_REPUBLIC]: licenseAgreementContentCzechRepublic,
    [Country.DENMARK]: licenseAgreementContentDenmark,
    [Country.FRANCE]: licenseAgreementContentFrance,
    [Country.GERMANY]: licenseAgreementContentGermany,
    [Country.ITALY]: licenseAgreementContentItaly,
    [Country.NETHERLANDS]: licenseAgreementContentNetherlands,
    [Country.NORWAY]: licenseAgreementContentNorway,
    [Country.SWEDEN]: licenseAgreementContentSweden,
    [Country.SWITZERLAND]: licenseAgreementContentSwitzerland,
    [Country.UNITED_KINGDOM]: licenseAgreementContentUnitedKingdom,
    [Country.USA]: licenseAgreementContentUsa,
    [Country.SPAIN]: licenseAgreementContentUsa, // TODO: Add Spain license agreement content
    [Country.JAPAN]: licenseAgreementContentUsa, // TODO: Add Japan license agreement content
    [Country.PORTUGAL]: licenseAgreementContentUsa, // TODO: Add Portugal license agreement content
    [Country.KOREA]: licenseAgreementContentUsa, // TODO: Add Portugal license agreement content
  };

  const content = ContentByCountryMap[country];

  return (
    <StyledLicenseAgreementContent>
      <div
        className="license-agreement-content__content"
        dangerouslySetInnerHTML={{ __html: content[CURRENT_LICENSE_AGREEMENT_VERSION] }}
      />
    </StyledLicenseAgreementContent>
  );
};
